import React from "react";
import {translate} from "../../selectors/translations";
import {formatDate, milliToIMin} from "../../utils/datetime";
import ProgressLine from "../../components/CognitiveTestCard/ProgressLine";
import {SCORE_RISK_SETUP, TIME_RISK_SETUP} from "../../components/CognitiveTestCard/CognitiveTestCard";
import useTranslate from "../../hooks/useTranslate";
import {roundValue} from "../../utils/numberFormatter";
import {
    AnswerDTO,
    LetterConfigDto,
    SectionDTO,
    SectionScreenDTO,
    UniqueScreenDTO,
    UniqueScreenMetadata
} from "../../sdk";
import { QuestionerAnswerWithSummarization } from "./calculate-sum";

type CognitivePdfComponentProps = {
    cognitive: QuestionerAnswerWithSummarization;
    currentLanguage: string;
}

type CognitiveSectionProps = {
    section: SectionDTO;
    answers: AnswerDTO[];
}


const CognitiveSection: React.FC<CognitiveSectionProps> = (props: CognitiveSectionProps) => {
    const {section, answers} = props;
    const translate = useTranslate();

    let [motorcycleActualPoints, motorcycleMaxPoints] = [0, 0]
    let [rememberWordsActualPoints] = [0]
    const motorcyclesSimilarityConfig = section.screens.find(el => el.screen.slug === 'motorcycles-and-trucks-similarity')
    const rememberWordsConfig = section.screens.find(el => el.screen.slug === 'remember-words')

    function getPoints(item: SectionScreenDTO): number[] {
        const screen = item.screen;
        const answer = answers.find(el => el.screen === screen.id);

        let actualPoints = 0;
        answer && answer.points?.forEach(num => {
            actualPoints += num;
        })

        let maxPoints = 0
        switch (screen.slug) {
            case "current-year":
                maxPoints = 1
                break;
            case "current-month":
                maxPoints = 1
                break;
            case "current-day":
                maxPoints = 1
                break;
            case "current-day-of-week":
                maxPoints = 1
                break;
            case "month-in-future":
                maxPoints = 1
                break;
            case "draw-cube":
                maxPoints = 1
                break;
            case "draw-clock":
                maxPoints = 5
                break;
            case "clock-time":
                maxPoints = 5
                break;
            case "connect-circles":
                maxPoints = 3
                break;
            case "coding":
                maxPoints = 6
                break;
            case "remember-words":
                maxPoints = 1
                break;
            case "remember-words-2":
                maxPoints = 1
                break;
            case "numbers":
                maxPoints = 4
                break;
            case "numbers-backwards":
                maxPoints = 4
                break;
            case "subtraction":
                maxPoints = 5
                break;
            case "remember-patterns":
                maxPoints = 5
                break;
            case "name-animal":
                maxPoints = 1
                break;
            case "letter-A":
                maxPoints = 1
                break;
            case "letter-F":
                maxPoints = 1
                break;
            case "abstraction-vehicles":
                maxPoints = 1
                break;
            case "abstraction-fruits":
                maxPoints = 1
                break;
            case "select-remembered-pattern":
                maxPoints = 5
                break;
            case "name-remembered-words":
                maxPoints = 5
                break;
            case "motorcycles-and-trucks-similarity":
                maxPoints = 1
                break;
            case "apples-and-pears-similarity":
                maxPoints = 1
                break;
            default:
                break
        }
        return [actualPoints, maxPoints]
    }

    if (motorcyclesSimilarityConfig) {
        [motorcycleActualPoints, motorcycleMaxPoints] = getPoints(motorcyclesSimilarityConfig);
    }
    if (rememberWordsConfig) {
        [rememberWordsActualPoints] = getPoints(rememberWordsConfig);
    }

    function renderSection(key: string, title: string, actualPoints: number, maxPoints: number): JSX.Element {
        return <div key={key} className="spoiler_box">
            <div className={'spoiler_header diagnostic_question_header'}>
                <div className="flex justify-between">
                    <div className="flex-item--shrink">
                        <h3>{title}</h3>
                    </div>
                    <div className="flex-item--shrink"><span>{translate('Points')}&nbsp;
                        <strong>{roundValue(actualPoints)}</strong> / {maxPoints}</span></div>
                </div>
            </div>
        </div>
    }

    function combineSections(item: SectionScreenDTO, actualPoints: number, maxPoints: number, index: number): JSX.Element {
        const key = item.screen.id! + index;
        switch (item.screen.slug) {
            case 'letter-A':
            case 'letter-F':
                const metadata: UniqueScreenMetadata = (item.screen as UniqueScreenDTO).metadata
                return renderSection(key, translate(metadata.title) + ' ' + (metadata.config as LetterConfigDto).letter, actualPoints, maxPoints)
            case 'motorcycles-and-trucks-similarity':
                return <></>
            case 'apples-and-pears-similarity':
                return renderSection(key, translate('cognitive.abstraction-intro.title'), actualPoints + motorcycleActualPoints, maxPoints + motorcycleMaxPoints)
            case 'remember-words':
                return <></>
            case 'remember-words-2':
                return renderSection(key, translate('cognitive.remember_words.title'), actualPoints + rememberWordsActualPoints, maxPoints)
            default:
                return renderSection(key, translate(item.screen.metadata.title), actualPoints, maxPoints)
        }
    }

    return (
        <div className="exercise_category break_inside--avoid_page">
            <h3>{translate("questioner.cognitive.section." + section.slug).toUpperCase()}</h3>
            <section className="diagnostic_test_body">
                {
                    section.screens.filter(el => el.screen.kind !== "section-title").map((item, i: number) => {
                        const [actualPoints, maxPoints] = getPoints(item);
                        return (
                            combineSections(item, actualPoints, maxPoints, i)
                        )
                    })
                }
            </section>
        </div>
    );
}


const CognitivePdfComponent: React.FC<CognitivePdfComponentProps> = (props: CognitivePdfComponentProps) => {
    const {cognitive} = props;

    return (
        <>
            <h1 className="header2 margin-top pb-10 break_before--page">{translate('pdf.cognitive')} (GSCT*)</h1>
            <div className="cognitive-results-item-wrapper">
                <div className="cognitive-date">
                    <span>{formatDate(cognitive.updatedAt || 0)}</span>
                </div>
                <div className="cognitive-results-item-wrap">
                    <div className="cognitive-results-item cognitive-score">
                        <ProgressLine
                            isRevert={false}
                            noPrint={true}
                            infoLegendKey={'riskLegend'}
                            legend={`${translate('Points')}: <strong>${roundValue(cognitive.sumOfPoints || 0)}</strong> / 59`}
                            progressValue={cognitive.sumOfPoints || 0}
                            lineSetup={SCORE_RISK_SETUP}/>
                        <span className="cognitive-results-suspect">{translate('Suspected')}:</span>
                    </div>
                    <div className="cognitive-results-item cognitive-time">
                        <ProgressLine
                            noPrint={true}
                            infoLegendKey={'riskLegend'}
                            progressValue={(Math.round((cognitive.sumOfTime || 0) / 1000))}
                            legend={`${translate('Time to take test')}: <strong>${milliToIMin(cognitive.sumOfTime || 0)} min</strong>`}
                            lineSetup={TIME_RISK_SETUP}
                            isRevert={true}/>
                    </div>
                </div>
            </div>

            {
                cognitive.questioner.sections.map((section, i: number) =>
                    <CognitiveSection
                        key={section.slug + i}
                        section={section}
                        answers={cognitive.answers}
                    />
                )
            }
            <div className="cognitive-explanation break_inside--avoid_page">
                <div className="flex justify-between">
                    <div className="left">
                        <p><b>{translate('pdf.cognitive.instruction-1.title')}</b></p>
                        <span dangerouslySetInnerHTML={{__html: translate('pdf.cognitive.instruction-1')}}></span>
                    </div>
                    <div className="right">
                        <p><b>{translate('pdf.cognitive.instruction-2.title')}</b></p>
                        <span dangerouslySetInnerHTML={{__html: translate('pdf.cognitive.instruction-2')}}></span>
                    </div>
                </div>
                <p className="pt-10" style={{fontSize: '9px'}}>{translate('pdf.cognitive.copyright')}</p>
            </div>
        </>
    )
}

export default CognitivePdfComponent;
