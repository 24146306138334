import React from 'react';
import Toggleable from '../../rc-components/Toggleable';
import SelectorBox from '../../components/SelectorBox';
import DropdownMenu from '../../components/DropdownMenu';
import useFormStateHandler from '../../hooks/formStateHandler';
import { PatientFilters, FilterChangedData } from '../../store/Patients/types';
import { translate } from '../../selectors/translations';
import {useSelector} from "react-redux";
import {getInstitutionsSelector} from "../../selectors/institutions";
import {Institution} from "../../store/Institutions/types";
import {authRoles} from "../../selectors/auth";

const genderArr = ['all', 'male', 'female'];
const langArr = ['all', 'en', 'sv', 'da'];

type FiltersFormProps = {
  filtersChanged: (data: FilterChangedData) => void;
  filters: PatientFilters;
  closeFilters: (id: string) => void;
}

const FiltersForm: React.FC<FiltersFormProps> = (props: FiltersFormProps) => {
  const { filtersChanged, filters, closeFilters } = props;
  const { gender, language, ageMin, ageMax, scoreMin, scoreMax, institution } = filters;
  const institutions = useSelector(getInstitutionsSelector);
  const roles = useSelector(authRoles);
  const { inputs, handleSubmit, handleInputChange } = useFormStateHandler(filtersChanged, { gender, language, ageMin, ageMax, scoreMin, scoreMax, institution });


  const onSearchClick = (e: any) => {
    handleSubmit(e);
    closeFilters('')
  }

  return (
    <div className="search_filters-block">
      <div className="filters-block_title">{translate('filters')}</div>
      <div className="control-holder"><label>{translate('age')}</label>
        <div className="flex-container">
          <div className="flex-item item-sm-6">
            <label>min</label>
            <input type="number" onChange={handleInputChange} name="ageMin" value={inputs.ageMin} />
          </div>
          <div className="flex-item item-sm-6">
            <label>max</label>
            <input type="number" onChange={handleInputChange} name="ageMax" value={inputs.ageMax} />
          </div>
        </div>
      </div>
      {/*<div className="control-holder"><label>{translate('latest score')} {`(0 - 59 ${translate('Points')})`}</label>*/}
      {/*  /!*<div className="slider-range">{`${inputs.scoreMin} - ${inputs.scoreMax} ${translate('Points')}`}</div>*!/*/}
      {/*  <div className="flex-container">*/}
      {/*    <div className="flex-item item-sm-6">*/}
      {/*      <label>{translate('min')}</label>*/}
      {/*      <input type="number" onChange={handleInputChange} name="scoreMin" value={inputs.scoreMin} />*/}
      {/*    </div>*/}
      {/*    <div className="flex-item item-sm-6">*/}
      {/*      <label>{translate('max')}</label>*/}
      {/*      <input type="number" onChange={(e) => handleMaxScoreChane(e)} name="scoreMax" max="59" value={inputs.scoreMax} />*/}
      {/*      {errors.includes('scoreMax') && <span style={{fontSize: '12px', color: 'red'}}>{translate('maxScoreExceeded')}</span>}*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <Toggleable>
        {
          (dropdownId: string, setDropdownId: (id: string) => void): React.ReactElement => (
            <div>
              { roles.includes('admin') &&
                  <div className="control-holder"><label>Institution</label>
                    <SelectorBox
                        type={"institution"}
                        Elem={
                          <DropdownMenu
                              show={dropdownId === 'institution'}
                              onClick={(val): void => (inputs[dropdownId] = val, setDropdownId(val))}
                              items={institutions.map((el: Institution) => ({
                                title: el.name,
                                propToPropagate: el.name,
                                isSelected: el.name === inputs.institution
                              }))}/>
                          // items={genderArr.map((i: string) => ({ title: i, propToPropagate: i, isSelected: i === inputs.gender }))} />
                        }
                        dropdownId={dropdownId}
                        items={institutions.map((el: Institution) => ({
                          title: el.name,
                          propToPropagate: el.name,
                          isSelected: el.name === inputs.institution
                        }))}
                        // items={genderArr.map((i: string) => ({ title: i, propToPropagate: i, isSelected: i === inputs.gender }))}
                        onToggleMenu={setDropdownId}
                    ></SelectorBox>
                  </div>
              }
              <div className="control-holder"><label>{translate('gender')}</label>
                <SelectorBox
                  type={"gender"}
                  Elem={
                    <DropdownMenu
                      show={dropdownId === 'gender'}
                      onClick={(val): void => (inputs[dropdownId] = val, setDropdownId(val))}
                      items={genderArr.map((i: string) => ({ title: i, propToPropagate: i, isSelected: i === inputs.gender }))} />
                  }
                  dropdownId={dropdownId}
                  items={genderArr.map((i: string) => ({ title: i, propToPropagate: i, isSelected: i === inputs.gender }))}
                  onToggleMenu={setDropdownId}
                ></SelectorBox>
              </div>
              <div className="control-holder"><label>{translate('selectLanguage')}</label>
                <SelectorBox
                  dropdownId={dropdownId}
                  type={"language"}
                  Elem={
                    <DropdownMenu
                      show={dropdownId === 'language'}
                      onClick={(val): void => (inputs[dropdownId] = val, setDropdownId(val))}
                      items={langArr.map((i: string) => ({ title: i, propToPropagate: i, isSelected: i === inputs.language }))} />
                  }
                  items={langArr.map((i: string) => ({ title: i, propToPropagate: i, isSelected: i === inputs.language }))}
                  onToggleMenu={setDropdownId}
                ></SelectorBox>
              </div>
            </div>
          )
        }
      </Toggleable>

      <div className="btn-holder flex justify-end">
        <button onClick={(): void => (filtersChanged({ gender: 'all', language: 'all', ageMin: '0', ageMax: '150', scoreMin: '0', scoreMax: '59', institution: '' }), closeFilters(''))} className="g_btn g_btn--outlined t_uppercase" type="button">{translate('clear')}</button>
        <button onClick={(e): void => onSearchClick(e)} className="g_btn g_btn--primary t_uppercase" type="button">{translate('search')}</button></div>
    </div>
  )
}

export default FiltersForm;
